export const ROLES = {
  HOD: 'hod',
  PGT: 'pgt',
  TGT: 'tgt',
};

export const USER_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const USER_ROLES = {
  ADMIN: 'admin',
  HOD: 'hod',
  TGT: 'tgt',
  PGT: 'pgt',
};
