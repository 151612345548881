/* eslint-disable react/prop-types */
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const RHFAutoComplete = ({
  options = [],
  getOptionLabel,
  defaultValue = null,
  name,
  label,
  multiple = false,
  isLoading = false,
  isOptionEqualToValue = () => {},
  renderOption,
  isDisabled = false,
  isOverride = false,
  onChangeHandler = () => {},
  onChangeAllHandler = () => {},
  rank = 0,
  ...rest
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      render={({ field: { onChange, value, ref, ...props }, fieldState: { error } }) => (
        <>
          <Autocomplete
            disabled={isDisabled}
            options={options}
            autoComplete
            autoHighlight
            getOptionLabel={getOptionLabel}
            renderInput={(params) => (
              <TextField
                {...params}
                {...rest}
                label={label}
                error={!!error}
                helperText={error?.message || error?.userName?.message}
                disabled={isLoading || isDisabled}
                inputRef={ref}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            isOptionEqualToValue={isOptionEqualToValue}
            renderOption={renderOption}
            onChange={(e, data) => {
              onChange(data);
              onChangeHandler(rank);
              if (isOverride) onChangeAllHandler(e, data);
            }}
            multiple={multiple}
            value={value}
            {...props}
          />
        </>
      )}
      onChange={([, data]) => {
        return data;
      }}
      defaultValue={defaultValue}
      name={name}
    />
  );
};

export default RHFAutoComplete;
