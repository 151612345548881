import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_DASHBOARD } from '../routes/paths';
import { USER_ROLES } from '../constants/keywords';

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, user } = useAuth();

  if (isAuthenticated) {
    return (
      <Navigate
        to={
          [USER_ROLES.PGT, USER_ROLES.TGT].includes(user?.designation)
            ? PATH_DASHBOARD.generatePaper.viewEditPaper
            : PATH_DASHBOARD.root
        }
      />
    );
  }

  return <>{children}</>;
}
